import React, { useState } from 'react'
import { UndoOutlined, EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export const FloatMenu = ({ undoHandler }) => {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <>
            <div style={styles.floatButton}>
                <Button
                    type={!showMenu ? 'primary' : 'secondary'}
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => setShowMenu(!showMenu)}
                    size="large"
                />
                {showMenu && (
                    <div style={styles.menu}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<UndoOutlined />}
                            onClick={undoHandler}
                            size="large"
                        />
                    </div>
                )}
            </div>
        </>
    )
}

const styles = {
    floatButton: {
        position: 'fixed',
        right: 10,
        bottom: 10,
    },
    menu: {
        position: 'absolute',
        bottom: 55,
    },
}
