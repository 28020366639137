import * as helpers from '@turf/helpers'
import memoizeOne from 'memoize-one'

import { DEFAULT_HIGHWAY_HAZARD_VALUE, HIGHWAY_HAZARD_OPTIONS } from 'utils/HighwayContent'

export const lightSchemePolygonsColour = 'rgba(0,0,128,0.15)'
export const darkSchemePolygonsColour = 'rgba(171, 228, 255,0.15)'
export const lightSchemePolylinesColour = 'rgb(0, 0, 128)'
export const darkSchemePolylinesColour = 'rgb(171, 228, 255)'
export const lightSchemePolylinesFeatureStateColour = 'rgb(25, 25, 128)'
export const darkSchemePolylinesFeatureStateColour = 'rgb(196, 236, 255)'

const getPolygonsById = memoizeOne((polygons) => {
    return new Map(polygons.features.map((polygon) => [polygon.properties.id, polygon]))
})

export function getPolygonLayers(source, id) {
    return [
        {
            id,
            type: 'fill',
            source,
            paint: {
                'fill-color': ['get', 'colorCode'],
                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.5],
            },
        },
        {
            id: id + '-line',
            source,
            type: 'line',
            paint: {
                'line-color': ['get', 'colorCode'],
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1],
            },
        },
    ]
}

export const createFeatureCollection = (products, polygons, forHwyHazard = false) => {
    const features = []

    for (const product of products) {
        let colorCode = product.colour
        if (forHwyHazard) {
            colorCode = HIGHWAY_HAZARD_OPTIONS.get(product.hazard)
        }

        for (const polygonId of product.polygons) {
            const polygonsById = getPolygonsById(polygons)
            const polygon = polygonsById.get(polygonId)
            const properties = {
                colorCode,
                name: product.name,
                polygonId,
            }
            features.push(helpers.feature(polygon.geometry, properties))
        }
    }

    const featureCollection = helpers.featureCollection(features)

    return featureCollection
}
