import useSWRImmutable from 'swr/immutable'

import { useLocale } from 'stores/UserStore'
import { fetchPolygons } from 'services/Polygons'

export const usePolygons = () => {
    const locale = useLocale()

    const key = ['/geospatial/polygons' + locale]

    const { data, isLoading } = useSWRImmutable(key, () => fetchPolygons(locale))

    if (isLoading) {
        return null
    }

    return data.data
}

export const usePolygonCentroids = () => {
    const polygons = usePolygons()

    return {
        ...polygons,
        features: polygons.features.map((feature) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: generateCoordinates(feature.properties),
            },
            properties: {
                ...feature.properties,
                display_name: generateDisplayName(feature.properties),
            },
        })),
    }
}

function generateCoordinates(properties) {
    if (properties.centroid) {
        // AvCan format: feature.properties.centroid
        return properties.centroid
    } else if (properties.CENTROID_X && properties.CENTROID_Y) {
        // CAIC format: feature.properties.CENTROID_X, feature.properties.CENTROID_Y
        return [properties.CENTROID_X, properties.CENTROID_Y]
    } else {
        return null
    }
}

function generateDisplayName(properties) {
    if (properties.polygon_name) {
        // AvCan format: feature.properties.polygon_name
        return properties.polygon_name
    } else if (properties.GlobalID) {
        // CAIC format: feature.properties.GlobalID
        return properties.GlobalID
    } else {
        return null
    }
}
