import React, { useState, useEffect } from 'react'

import { Row, Col, Button, Form, Typography } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { LIKELIHOODS_TO_VALUES, VALUES_TO_LIKELIHOODS, schema } from 'utils/AvalancheProblems'
import { HAZARD_LIKELIHOOD_MATRIX } from 'utils/AvalancheProblems'
import { getWindowDimensions } from 'utils/screen'
import { getCharacterCount } from 'utils/String'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { AvalancheProblemForm } from './AvalancheProblemForm'
import { HazardChart } from 'components/AvalancheForecast/Content/AvalancheProblems/HazardChart'
import { AccessibleHazardChart } from 'components/AvalancheForecast/Content/AvalancheProblems/AccessibleChart'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { useTerrainSelectorForm } from 'components/SelectorForm/TerrainSelectorForm'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import { useCharacterLimits } from 'hooks/Settings'
import { useEnhancedAccessibility } from 'stores/UserStore'
import { sendErrorNotification } from 'utils/Notifications'

const GRID_THRESHOLD = 1400
const MAX_STATEMENTS = 8

export const AvalancheProblemEditor = ({
    content,
    problemData = null,
    hideModal,
    saveProblem,
    editing,
    terrainTravelAdvice,
    currentDay,
}) => {
    // External
    const { Title } = Typography
    const [form] = Form.useForm()
    const intl = useIntl()

    // Utils
    const locale = useLocale()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const actionButtonLabel = !editing ? messages.submitProblem : messages.updateProblem
    const characterLimit = useCharacterLimits().problemDiscussion
    const enhancedAccessibility = useEnhancedAccessibility()

    // Errors and chart status
    const [weakLayerSelectError, setWeakLayerSelectError] = useState(false)
    const [aspectElevationsError, setAspectElevationsError] = useState(false)
    const [hazardChartError, setHazardChartError] = useState(false)
    const [guidanceEnabled, setGuidanceEnabled] = useState(false)

    // Problem State
    const [problem, setProblem] = useState(problemData || schema(locale))
    const [selectedStatements, setSelectedStatements] = useState(terrainTravelAdvice)

    // Window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // Close modal
    const handleCancel = () => {
        hideModal()
    }

    // Terrain and travel advice
    const statementSelectHandler = (selectedStatements) => {
        const newSelectedStatements = [...selectedStatements].map((statement) => statement.key)
        setProblem({
            ...problem,
            terrainTravelAdvice: newSelectedStatements,
        })
    }

    const { SelectorForm } = useTerrainSelectorForm(selectedStatements, statementSelectHandler, '80vh', MAX_STATEMENTS)

    // Aspect and elevation
    const updateAspectsAndElevations = (list) => {
        setProblem({
            ...problem,
            aspectElevations: list,
        })
    }

    // Comments (ie trnaslations)
    const addNewTranslation = (language) => {
        setProblem({
            ...problem,
            comment: {
                ...problem.comment,
                [language]: '',
            },
        })
    }

    const removeTranslation = (language) => {
        const newTranslations = { ...problem.comment }
        delete newTranslations[language]

        setProblem({
            ...problem,
            comment: newTranslations,
        })
    }

    const handleEditorChange = (language, value) => {
        setProblem({
            ...problem,
            comment: {
                ...problem.comment,
                [language]: value,
            },
        })
    }

    // Conceptual model fields
    useEffect(() => {
        // Check if we're ready to generate the chart polygons
        // If we have sensitivity, distribution and typical size, we can generate the chart polygons
        if (problem.sensitivity && problem.distribution && problem.typicalSize) {
            setGuidanceEnabled(true)

            // if hazardSize isn't defined, we can assume that none of the chart polygons have been generated
            if (!problem.hazardSize) {
                generateChartPolygons()
            }
        }
    }, [problem])

    const handleChartChange = (val) => {
        const size = { min: val.size.from, max: val.size.to }
        const likelihoodRange = { min: val.likelihood.from, max: val.likelihood.to }
        const hazardSize = val.centroid.size
        const likelihood = val.centroid.likelihood

        setProblem({
            ...problem,
            size,
            likelihoodRange,
            hazardSize,
            likelihood,
        })
    }

    const generateChartPolygons = () => {
        if (!problem.hazardSize) {
            setProblem({
                ...problem,
                size: { min: 1, max: 2 },
                likelihoodRange: { min: 'unlikely', max: 'possible' },
                hazardSize: 1.5,
                likelihood: 'possible_unlikely',
            })
        }
    }

    const acceptHazardEval = () => {
        // create a chartPolygon around the centroid
        const hazardLikelihood = HAZARD_LIKELIHOOD_MATRIX[problem.distribution][problem.sensitivity]
        const hazardLikelihoodIndex = LIKELIHOODS_TO_VALUES.get(hazardLikelihood)
        const minHazardLikelihoodIndex = Math.max(hazardLikelihoodIndex - 0.5, 1)
        const maxHazardLikelihoodIndex = Math.min(hazardLikelihoodIndex + 0.5, 5)
        const minHazardLikelihood = VALUES_TO_LIKELIHOODS.get(minHazardLikelihoodIndex)
        const maxHazardLikelihood = VALUES_TO_LIKELIHOODS.get(maxHazardLikelihoodIndex)

        setProblem({
            ...problem,
            size: { min: Math.max(problem.typicalSize - 0.5, 1), max: Math.min(problem.typicalSize + 0.5, 5) },
            likelihoodRange: { min: minHazardLikelihood, max: maxHazardLikelihood },
            hazardSize: problem.typicalSize,
            likelihood: hazardLikelihood,
        })
    }

    const validateProblem = () => {
        let valid = true

        form.validateFields()

        if (problem.comment.en && characterLimit.en && getCharacterCount(problem.comment.en) > characterLimit.en) {
            sendErrorNotification(intl.formatMessage({ ...messages.saveProblemCharacterCountError }))
            valid = false
        }

        if (problem.comment.fr && characterLimit.fr && getCharacterCount(problem.comment.fr) > characterLimit.fr) {
            sendErrorNotification(intl.formatMessage({ ...messages.saveProblemCharacterCountError }))
            valid = false
        }

        if (!problem.problemType || !problem.distribution || !problem.typicalSize || !problem.sensitivity) {
            valid = false
        }

        if (!problem.weakLayer.id) {
            setWeakLayerSelectError(true)
            valid = false
        } else {
            setWeakLayerSelectError(false)
        }

        if (!problem.aspectElevations.length) {
            setAspectElevationsError(true)
            valid = false
        } else {
            setAspectElevationsError(false)
        }

        if (
            !Object.keys(problem.size).length ||
            !Object.keys(problem.likelihoodRange).length ||
            !problem.hazardSize ||
            !problem.likelihood
        ) {
            setHazardChartError(true)
            valid = false
        } else {
            setHazardChartError(false)
        }

        if (valid) {
            try {
                saveProblem(problem, problem.terrainTravelAdvice)
            } catch (e) {
                console.log(e)
            }
            hideModal()
        }
    }

    const chartPolygons = {
        centroid: { size: problem.hazardSize, likelihood: problem.likelihood },
        size: { from: problem.size.min, to: problem.size.max },
        likelihood: { from: problem.likelihoodRange.min, to: problem.likelihoodRange.max },
    }
    const formMarker = {
        size: problem.typicalSize,
        likelihood:
            problem.distribution && problem.sensitivity
                ? HAZARD_LIKELIHOOD_MATRIX[problem.distribution][problem.sensitivity]
                : null,
    }

    return (
        <div style={styles.container}>
            <Row>
                <Col span={windowDimensions.width < GRID_THRESHOLD ? 12 : 8}>
                    <AvalancheProblemForm
                        problem={problem}
                        content={content}
                        updateProblem={setProblem}
                        form={form}
                        weakLayerSelectError={weakLayerSelectError}
                    />
                    {windowDimensions.width < GRID_THRESHOLD && (
                        <Row gutter={16}>
                            <Col span={12}>
                                <AspectElevation
                                    updateDraftItem={(item) => {
                                        updateAspectsAndElevations(item.aspectElevations)
                                    }}
                                    draftItem={{}}
                                    aspectElevations={problem.aspectElevations}
                                    update={() => {}}
                                    create={() => {}}
                                    colour={'#1890ff'}
                                    errorStatus={aspectElevationsError}
                                />
                            </Col>
                            <Col span={12}>
                                {hazardChartError && (
                                    <p style={styles.error}>
                                        <FormattedMessage {...messages.hazardRequired} />
                                    </p>
                                )}
                                {enhancedAccessibility ? (
                                    <AccessibleHazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                ) : (
                                    <HazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                    <TranslatedTextField
                        handleChange={handleEditorChange}
                        handleAddTranslation={addNewTranslation}
                        handleRemoveTranslation={removeTranslation}
                        translations={problem.comment}
                        plainText={false}
                        translationRequired={currentDay?.position !== 0}
                        characterLimit={characterLimit}
                    />
                    <div data-test={'editors'}>
                        <Button
                            style={styles.rec}
                            onClick={acceptHazardEval}
                            disabled={!guidanceEnabled}
                            data-test={'guidanceButton'}
                        >
                            <FormattedMessage {...messages.guidance} />
                        </Button>
                    </div>
                </Col>
                <Col style={styles.visuals} span={windowDimensions.width < GRID_THRESHOLD ? 1 : 9}>
                    {windowDimensions.width > GRID_THRESHOLD && (
                        <Row>
                            <Col span={24}>
                                <AspectElevation
                                    updateDraftItem={(item) => {
                                        updateAspectsAndElevations(item.aspectElevations)
                                    }}
                                    draftItem={{}}
                                    aspectElevations={problem.aspectElevations}
                                    update={() => {}}
                                    create={() => {}}
                                    colour={'#1890ff'}
                                    errorStatus={aspectElevationsError}
                                />
                            </Col>
                            <Col span={24}>
                                {hazardChartError && (
                                    <p style={styles.error}>
                                        <FormattedMessage {...messages.hazardRequired} />
                                    </p>
                                )}
                                {enhancedAccessibility ? (
                                    <AccessibleHazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                ) : (
                                    <HazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col span={windowDimensions.width < GRID_THRESHOLD ? 11 : 7}>
                    <Title style={styles.terrainAndTravelAdvice} level={4}>
                        <FormattedMessage {...messages.terrainAndTravelAdvice} />
                    </Title>
                    {SelectorForm}
                </Col>
            </Row>
            <div style={styles.footer}>
                <Button onClick={validateProblem} type="primary" data-test={'saveProblemButton'}>
                    <FormattedMessage {...actionButtonLabel} />
                </Button>
                <Button onClick={handleCancel} style={styles.cancel}>
                    <FormattedMessage {...messages.cancel} />
                </Button>
            </div>
        </div>
    )
}

const styles = {
    error: {
        color: 'var(--red)',
        textAlign: 'center',
    },
    errorRight: {
        color: 'var(--red)',
    },
    container: {
        paddingBottom: '75px',
    },
    visuals: {
        padding: ' var(--s0)',
    },
    rec: {
        float: 'right',
        marginTop: '-33px', // needed to align the guidance button with the  add language button
    },
    footer: {
        justifyContent: 'flex-end',
        display: 'flex',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        background: 'var(--background-colour)',
        height: '60px',
        borderTop: '1px solid var(--grey-light)',
        padding: '15px 10px 10px',
        right: '0px',
    },
    cancel: {
        marginLeft: 'var(--s0)',
    },
    publicLabel: {
        marginBottom: '3px',
        textTransform: 'capitalize',
    },
    toggleContainers: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    terrainAndTravelAdvice: {
        fontWeight: 400,
    },
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
    },
    translationTag: {
        marginBottom: 'var(--s-3)',
        marginLeft: 'var(--s-1)',
    },
    roseToggle: {
        marginBottom: 'var(--s-2)',
    },
}
