import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Row, Tag } from 'antd'

import { useLocale } from 'stores/UserStore'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'
import { AddLanguageDropdown } from 'components/Language/AddLanguageDropdown'
import messages from 'services/intl/messageDefinitions'

export const TranslatedTextField = ({
    handleChange,
    handleAddTranslation,
    handleRemoveTranslation,
    translations,
    plainText,
    characterLimit,
    translationRequired = true,
    position = 0,
}) => {
    const locale = useLocale()

    const handleTextChange = (value, language) => {
        handleChange(language, value)
    }

    const addTranslation = (language) => {
        handleAddTranslation(language)
    }

    const removeTranslation = (language) => {
        handleRemoveTranslation(language)
    }

    return (
        <>
            {translations &&
                Object.keys(translations)
                    // sort alphabetically, but put the current locale first
                    .sort((a, b) => {
                        if (a === locale) {
                            return -1
                        }
                        if (b === locale) {
                            return 1
                        }
                        return a.localeCompare(b)
                    })
                    .map((language, index) => {
                        const key = language + '-' + index
                        /* Character limits can be a number or an object with language keys ({en: 100})
                        If it's a number, use that for all languages
                        If it's an object, use the limit for the current language, or null if there is none specified.
                        We're doing this because Parks wants a limit on english text but no limit for french text. */
                        const characterLimitLangage =
                            characterLimit && typeof characterLimit === 'object'
                                ? characterLimit[language] || null
                                : characterLimit

                        return (
                            <div key={key}>
                                <Row>
                                    <p style={styles.translationLabel}>
                                        {<FormattedMessage {...messages[language]} />}
                                    </p>
                                    {translationRequired && (
                                        <Tag color="red" style={styles.translationTag}>
                                            <FormattedMessage {...messages.translationRequired} />
                                        </Tag>
                                    )}
                                </Row>
                                <RichTextEditor
                                    content={translations[language]}
                                    plainText={plainText}
                                    handleEditorStateChange={(value) => handleTextChange(value, language)}
                                    characterLimit={characterLimitLangage}
                                    removeHandler={() => removeTranslation(language)}
                                />
                            </div>
                        )
                    })}
            <AddLanguageDropdown existingTranslations={translations} handleClick={(lang) => addTranslation(lang)} />
        </>
    )
}

const styles = {
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
    },
    translationTag: {
        marginBottom: 'var(--s-3)',
        marginLeft: 'var(--s-1)',
    },
}
