import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchHighwayDiscussions } from 'services/HighwayDiscussions'
import { HighwayDiscussionStore } from 'stores/HighwayDiscussionStore'
import { filterAndSort, unassignedCount } from 'utils/Product'
import { usePolylines } from 'hooks/polylines'

export const useHighwayDiscussions = () => {
    const language = useLocale()
    const key = ['products/highwaydiscussion', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchHighwayDiscussions(language))

    return {
        highwayDiscussions: data?.data,
        isLoading,
        mutate,
    }
}

export const useFilteredHighwayDiscussions = () => {
    const { highwayDiscussions, isLoading } = useHighwayDiscussions()
    const filters = HighwayDiscussionStore.useFilters()

    return isLoading ? [] : filterAndSort(highwayDiscussions, 'status', filters)
}

export const useActiveHighwayDiscussion = () => {
    const activeProductId = HighwayDiscussionStore.useActiveProductId()
    const { highwayDiscussions } = useHighwayDiscussions()
    const draftProduct = HighwayDiscussionStore.useDraftProduct()

    if (activeProductId === 'draft') {
        return draftProduct
    }

    return highwayDiscussions?.find((product) => product.id === activeProductId)
}

// Gotcha: polylines, not polygons
export const useUnassignedPolylines = () => {
    const polylines = usePolylines()

    const filteredProducts = useFilteredHighwayDiscussions()
    const draftProduct = HighwayDiscussionStore.useDraftProduct()

    if (!polylines || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polylines, filteredProducts, draftProduct)

    return count
}
